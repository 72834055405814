// 导入封装好的网络请求类工具
import Network from './network';
import Global from './global'
import qs from 'qs'

//获得微店列表
export const getShopList = (page, orgID) => Network.postToken(Global.URL_DYT + "/universal/localcomp/api/weidianpage", {
	pageIndex: page,
	pageSize: 10,
	object: {
		"orgs": [{
			"id": 0,
			"createTime": 0,
			"isDeleted": 0,
			"orgID": orgID,
			"localCompID": 0
		}]
	},
});
//获得微店列表大运461康辉559
export const getShopLists = (page, orgID) => Network.postToken(Global.URL_DYT + "/universal/localcomp/api/stmdpage", {
	pageIndex: page,
	pageSize: 10,
	object: {
		"orgs": [{
			"id": 0,
			"createTime": 0,
			"isDeleted": 0,
			"orgID": orgID,
			"localCompID": 0
		}]
	},
});
// 大运461 康辉559
//获得签证详情
export const getVisaDetail = (id) => Network.postToken(Global.URL_SWEET_APPLET, {
	"url": "https://applet.sweetour.com/api/VisaID",
	"type": 1,
	"par": JSON.stringify({
		"ID": id,
		"bigId": null
	})
});

//签证指定区域产品列表
export const getVisaAreaProduct = (id) => Network.postUrlencode(Global.URL_SWEET_SERVICE, qs.stringify({
	s: JSON.stringify({
		"tohrequest": {
			"callid": "141028",
			"transid": "1555289640259",
			"timestamp": "1555289640259",
			"ostype": "1",
			"imei": "imei",
			"imsi": "imsi",
			"version": "4.0.4",
			"svccont": {
				"areaId": id
			}
		}
	})
}));

//签证更多目的地列表
export const getVisaMoreCity = () => Network.postUrlencode(Global.URL_SWEET_SERVICE, qs.stringify({
	s: JSON.stringify({
		"tohrequest": {
			"callid": "141027",
			"transid": "1555289619872",
			"timestamp": "1555289619872",
			"ostype": "1",
			"imei": "imei",
			"imsi": "imsi",
			"version": "4.0.4",
			"svccont": {
				"userId": ""
			}
		}
	})
}));

//签证产品列表(带分页)
export const getVisaMain = (page = 1, pagesize = 8) => Network.postUrlencode(Global.URL_SWEET_SERVICE, qs.stringify({
	s: JSON.stringify({
		"tohrequest": {
			"callid": "141042",
			"transid": "1555289662259",
			"timestamp": "1555289662259",
			"ostype": "1",
			"imei": "imei",
			"imsi": "imsi",
			"version": "4.0.4",
			"svccont": {
				"type": "",
				"pageinfo": {
					"pagecount": page,
					"pagesize": pagesize
				}
			}
		}
	})
}));

//新闻详情
export const getNewsDetail = (id) => Network.postToken(Global.URL_DYT + '/lncctold/news/api/get', {
	"id": id
});

//新闻列表
export const getNewsList = (page = 1, pageSize = 7) => Network.postToken(Global.URL_DYT + '/lncctold/news/api/page', {
	"object": {
		"classID": 0,
		"parentID": 11
	},
	"pageIndex": page,
	"pageSize": pageSize,
	"total": 0
});

//获得甜程门票指定主题目的地列表
export const getSweetThemeDest = (themeId) => Network.postToken(Global.URL_SWEET_APPLET, {
	"url": "https://open.sweetour.com:9000/api/services/app/NewNearTravel/GetThemeDest?themeId=" + themeId,
	"type": 2,
	"par": ""
});


//获得甜程门票指定主题产品列表
export const getSweetTheme = (themeId, page = 1, pageSize = 15) => Network.postToken(Global.URL_SWEET_APPLET, {
	"url": "https://open.sweetour.com:9000/api/services/app/NewNearTravel/GetNearTravel_List",
	"type": 1,
	"par": JSON.stringify({
		"merchantId": 0,
		"pageIndex": page,
		"pageSize": pageSize,
		"tagName": "周边游",
		"themeId": "" + themeId,
		"organizationId": 1,
		"isZeroShow": false,
		"bigId": 0
	})
});

//获得指定产品详情的微信小程序二维码pagesAround/aroundPage/productPage/productPage
export const getDetailCode = (id, path = "pagesAround/aroundPage/productPage/productPage", applet = 3) => {
	return Network.postToken(Global.URL_SWEET_APPLET, {
		"url": "https://applet.sweetour.com/Applet/GetAppleShareQrcode",
		"type": 1,
		"par": JSON.stringify({
			"applet": applet,
			"scene": id,
			"page": path
		})
	})
};

//获得游轮产品详情
export const getShipDetail = (id) => Network.postToken(Global.URL_SWEET_APPLET, {
	"url": "https://liner.sweetour.com/ShipApp/GetShip?id=" + id,
	"type": 2,
	"par": ""
});

//获得周边游产品详情
export const getTicketDetail = (id) => Network.postToken(Global.URL_SWEET_APPLET, {
	"url": "https://open.sweetour.com:9000/api/services/app/NewNearTravel/GetNearTravelProductByID?id=" + id +
		"&MerchantId=0&OrganizationId=1&bigId=0",
	"type": 1,
	"par": ""
});

//获得研学详情/NewNearTravel/GetNearTravelProductByID?id=
export const getGoodsInfo = (id) => Network.post(Global.URL_XQSN + "/NewNearTravel/GetNearTravelProductByID?id=" + id +
	"&MerchantId=0&OrganizationId=60", {});

//获得跟团游产品详情
export const getGroupDetail = (id, packagecompany) => Network.postToken(Global.URL_DYT + '/team/api/teamgetfxwd', {
	"packagecompany": packagecompany,
	"id": id
});

//获得红色之旅产品列表
export const getRedProductList = (pageIndex = 1, pageSize = 7) => Network.postToken(Global.URL_DYT +
	'/team/mobile/indirect/hszl', {
		"pageIndex": pageIndex,
		"pageSize": pageSize,
		"object": {}
	});

//获得游轮目的地列表
export const getShipMoreCityList = () => Network.postToken(Global.URL_SWEET_APPLET, {
	"url": "https://liner.sweetour.com/ShipApp/GetDeparture",
	"type": 2,
	"par": ""
});

//获得游轮产品列表
export const getShipProductList = (pageIndex = 1, pageSize = 7, search = "") => Network.postToken(Global
	.URL_SWEET_APPLET, {
		"url": "https://liner.sweetour.com/ShipApp/GetShipList",
		"type": 1,
		"par": JSON.stringify({
			"pageIndex": pageIndex,
			"pageSize": pageSize,
			"keyword": search,
			"depId": 0,
			"desId": 0,
			"orderBy": 1
		})
	});

//获得周边游产品列表
export const getTicketList = (pageIndex = 1, pageSize = 7, search = "", themeId = 3) => Network.postToken(Global
	.URL_SWEET_APPLET, {
		"url": "https://open.sweetour.com:9000/api/services/app/NewNearTravel/GetNearTravel_List",
		"type": 1,
		"par": JSON.stringify({
			"keyWord": search,
			"pageIndex": pageIndex,
			"pageSize": pageSize,
			"organizationId": 20,
			"isZeroShow": false,
			"bigId": 0,
			"themeId": themeId > 3 ? themeId : undefined
		})
	});

//获得新指定主题更多目的地
export const getNewTitleMoreCityList = (theme) => Network.postToken(Global.URL_DYT + '/team/api/teamtheme/list', {
	"theme": "," + theme + ","
});

//获得新指定主题产品列表
export const getNewTitleProductList = (platformid, theme = "", pageIndex = 1, pageSize = 15, search = "") => Network
	.postToken(Global.URL_DYT + '/team/mobile/indirect/pagefxwd', {
		"pageIndex": pageIndex,
		"pageSize": pageSize,
		"object": {
			"title": search,
			"platformid": platformid,
			"is_sny": 2,
			"theme": theme,
		}
	});

//获得指定主题更多目的地
export const getThemeMoreCityList = (specialarea) => Network.postToken(Global.URL_DYT + '/team/api/teamarea/list', {
	"specialarea": "," + specialarea + ","
});

//获得指定主题产品列表
// export const getThemeProductList = (platformid, specialarea, pageIndex = 1, pageSize = 7, search = "") => Network
// 	.postToken(Global.URL_DYT + '/team/mobile/indirect/pagefxwd', {
// 		"pageIndex": pageIndex,
// 		"pageSize": pageSize,
// 		"object": {
// 			"title": search,
// 			"platformid": platformid,
// 			"is_sny": 2,
// 			"specialarea": "," + specialarea + ","
// 		}
// 	});
export const getThemeProductList = (pageIndex = 1, search = "") => Network
	.post(Global.URL_XQSN + '/NewNearTravel/GetNearTravel_List', {
		"pageIndex": pageIndex,
		// "pageSize": pageSize,
		"merchantId": 0,
		"tagName": '周边游',
		"organizationId": 60,
		"isZeroShow": false,
		"xqsnType": 0,
		"keyWord": search
	});

//获得东北定制游产品列表
export const getCustomProductList = (platformid, pageIndex = 1, pageSize = 8, search = "") => Network.postToken(Global
	.URL_DYT + '/team/mobile/indirect/pagefxwd', {
		"pageIndex": pageIndex,
		"pageSize": pageSize,
		"object": {
			"title": search,
			"platformid": platformid,
			"is_sny": 1
		}
	});

//获得东北定制游指定城市产品列表
export const getCustomCityProductList = (platformid, podId, pageIndex = 1, pageSize = 8, search = "") => Network
	.postToken(Global.URL_DYT + '/team/mobile/indirect/pagefxwd', {
		"pageIndex": pageIndex,
		"pageSize": pageSize,
		"object": {
			"title": search,
			"platformid": platformid,
			"is_sny": 1,
			"pods": [{
				"podId": podId
			}]
		}
	});

//获得首页轮播图
export const getHomeAdList = (id) => Network.postToken(Global.URL_SWEET_APPLET, {
	"url": "https://applet.sweetour.com/api/Applets_GNTeam",
	"type": 1,
	"par": JSON.stringify({
		id: id
	})
});

//获得省内跟团游产品列表
export const getProvinceProductList = (platformid, pageIndex = 1, pageSize = 7, search = "") => Network.postToken(Global
	.URL_DYT + '/team/mobile/indirect/pagefxwd', {
		"pageIndex": pageIndex,
		"pageSize": pageSize,
		"object": {
			"title": search,
			"platformid": platformid,
			"is_sny": 1,
			"theme": "24",
			"pods": [{
				"podID": 3087
			}]
		}
	});

//获得国内跟团游产品列表
export const getGroupProductList = (platformid, pageIndex = 1, pageSize = 7, search = "") => Network.postToken(Global
	.URL_DYT + '/team/mobile/indirect/pagefxwd', {
		"pageIndex": pageIndex,
		"pageSize": pageSize,
		"object": {
			"title": search,
			"platformid": platformid,
			"is_sny": 2,
			"pods": [{
				"podID": 3087
			}]
		}
	});

//获得国内跟团游 更多目的地
export const getGroupHotCity = () => Network.post(Global.URL_SWEET_APPLET, {
	"url": "https://applet.sweetour.com/api/GetGTLine",
	"type": 1,
	"par": JSON.stringify({
		"State": 0,
		"Count": 13
	})
});
// 获取小程序短连接
export const getAppleLinkCode = (applet) => Network.postToken(Global.URL_SWEET_APPLET, {
	"url": "https://applet.sweetour.com/Applet/GetAppleLinkCode",
	"type": 1,
	"par": JSON.stringify({
		"applet": applet
	})
});
//获得出境游 更多目的地
export const getGWTeamHotCity = () => Network.post(Global.URL_SWEET_APPLET, {
	"url": "https://applet.sweetour.com/api/Applets_GWTeam",
	"type": 1,
	"par": JSON.stringify({
		EndCity: 0,
		Id: 6617,
		Search: "",
		Sort: 0,
		StartCity: 0,
		Theme: 0,
		destinationCount: 8,
		pageIndex: 1,
		pageSize: 10,
		themeCount: 4,
	})
});
//获得出境游
export const getOutboundTravelList = (platformid, pageIndex = 1, pageSize = 7, search = "") => Network.postToken(Global
	.URL_DYT + '/team/mobile/indirect/pagefxwdcj', {
		"pageIndex": pageIndex,
		"pageSize": pageSize,
		"object": {
			"title": search,
			"platformid": platformid,
			"is_sny": 2,
			"pods": [{
				"podID": 3087
			}]
		}
	});