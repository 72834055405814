const URL_DYT = "https://api.dayuntong.com";
const URL_XQSN = "https://open.sweetour.com:9000/api/services/app";
const URL_SWEET_MER = "https://mer.sweetour.com:9000/"
const URL_SWEET_APPLET = "https://mer.sweetour.com:9000/api/services/app/NewNearTravel/Redirect"
const URL_SWEET_SERVICE = "https://service.sweetour.com/m/m_Service.asmx/M_InterfaceTrue"
const URL_APPLET = "https://applet.sweetour.com"

module.exports = {
	URL_DYT,
	URL_XQSN,
	URL_SWEET_MER,
	URL_SWEET_APPLET,
	URL_SWEET_SERVICE,
	URL_APPLET
}
