<template>
	<div class="footer-total">
		<div class="content">
			<img v-if="$app == 'kh'" class="bg" src="../assets/kh/dd@2x.png" />
			<!-- 大运判断地区 -->
			<section v-else-if="$app == 'dy'">
				<img v-if="org == 1" class="bg" src="../assets/dy/dl_dd@2x.png" />
				<img v-else-if="org == 2" class="bg" src="../assets/dy/jl_dd@2x.png" />
				<img v-else-if="org == 3" class="bg" src="../assets/dy/hrb_dd@2x.png" />
				<img v-else class="bg" src="../assets/dy/dd@2x.png" />
			</section>
			<!-- 备案路径 -->
			<div class="beian" @click="beian"></div>
			<div class="button-group">
				<div class="button-array1">
					<div class="button" @click="clickButton(3)">企业简介</div>
					<div class="button" @click="clickButton(4)">企业文化</div>
					<div class="button" @click="clickButton(5)">企业荣誉</div>
					<div class="button" @click="clickButton(12)">企业资质</div>
					<div class="button" @click="clickButton(7)">联系我们</div>
				</div>
				<div class="button-array2">
					<div class="button" @click="clickProduct(1)">跟团游</div>
					<div class="button" @click="clickProduct(2)">自由行·定制</div>
					<div class="button" @click="clickProduct(3)">景点门票</div>
					<div class="button" @click="clickProduct(4)">全球签证</div>
					<div class="button" @click="clickProduct(5)">东北游</div>
					<div class="button" @click="clickProduct(6)">火车游</div>
				</div>
				<div class="button-array3">
					<div class="button" @click="clickProduct(7)">邮轮游</div>
					<div class="button" @click="clickProduct(8)">研学·亲子</div>
					<div class="button" @click="clickProduct(9)">旅居康养</div>
					<div class="button" @click="clickProduct(10)">红色培训</div>
					<div class="button" @click="clickProduct(11)">房车·自驾</div>
					<div class="button" @click="clickProduct(12)">会议展览</div>
				</div>
				<div class="button-array4">
					<div class="button">会员活动</div>
					<div v-if="$app == 'kh'" class="button" @click="clickProduct(13)">
						康辉动态
					</div>
					<div v-else-if="$app == 'dy'" class="button" @click="clickProduct(13)">
						大运通动态
					</div>
				</div>
				<div class="button-array5">
					<div class="button" @click="clickButton(13)">营业网点</div>
					<div class="button">积分换礼</div>
					<div class="button" v-if="$app == 'dy'" @click="clickProduct(14)">
						微店店主
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			org: {
				type: String,
				default: "",
			}
		},
		data() {
			return {};
		},
		methods: {
			beian() {
				window.open('https://beian.miit.gov.cn/', '_blank')
			},
			clickProduct(e) {
				let index = e;
				let org = this.$route.query.org ? this.$route.query.org : ''
				if (index == 1) {
					//跟团游
					window.open("/ProductList?type=1&tab_name=2-1&org=" + org, "_blank");
				} else if (index == 2) {
					//自由行
					window.open("/About?index=11&tab_name=6-6&org=" + org, "_blank");
				} else if (index == 3) {
					//景点门票
					window.open("/ProductList?type=3&tab_name=4&org=" + org, "_blank");
				} else if (index == 4) {
					//签证
					window.open("/Visa?tab_name=7&org=" + org, "_blank");
				} else if (index == 5) {
					//东北游
					window.open("/ProductList?type=2&tab_name=3-1&org=" + org, "_blank");
				} else if (index == 6) {
					//火车游
					window.open("/ProductList?type=10&tab_name=11-1&org=" + org, "_blank");
				} else if (index == 7) {
					//游轮
					window.open("/ProductList?type=9&tab_name=10-1&org=" + org, "_blank");
				} else if (index == 8) {
					//研学
					window.open("/ProductList?type=4&tab_name=5&org=" + org, "_blank");
				} else if (index == 9) {
					//旅居康养
					window.open("/ProductList?type=13&tab_name=14&org=" + org, "_blank");
				} else if (index == 10) {
					//红色培训
					window.open("/ProductList?type=7&tab_name=8&org=" + org, "_blank");
				} else if (index == 11) {
					//房车自驾
					window.open("/ProductList?type=11&tab_name=12-1&org=" + org, "_blank");
				} else if (index == 12) {
					//会议展览
					window.open("/About?index=8&tab_name=13&org=" + org, "_blank");
				} else if (index == 13) {
					//康辉动态
					window.open("/NewsList&org=" + org, "_blank");
				} else if (index == 14) {
					//微店店主
					window.open("/ShopList?org=" + org, "_blank");
				}
			},
			clickButton(e) {
				let index = e;
				let org = this.$route.query.org ? this.$route.query.org : ''
				if (index == 6) {
					window.open("http://www.lncct.com:8033/bzhjb/index.htm", "_blank");
				} else {
					window.open("/About?index=" + index + "&org=" + org, "_blank");
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.footer-total {
		display: flex;
		width: 100%;
		height: 426px;
		background-color: #fff;
		justify-content: center;
		min-width: 1200px;

		.content {
			width: 1200px;
			background-color: #fff;
			position: relative;

			.bg {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 426px;
			}

			div.button-group {
				display: flex;
				position: absolute;

				div.button-array1 {
					position: absolute;
					left: 42px;
					top: 100px;
					display: flex;
					flex-direction: column;
				}

				div.button-array2 {
					position: absolute;
					left: 210px;
					top: 100px;
					display: flex;
					flex-direction: column;
				}

				div.button-array3 {
					position: absolute;
					left: 330px;
					top: 100px;
					display: flex;
					flex-direction: column;
				}

				div.button-array4 {
					position: absolute;
					left: 506px;
					top: 100px;
					display: flex;
					flex-direction: column;
				}

				div.button-array5 {
					position: absolute;
					left: 669px;
					top: 100px;
					display: flex;
					flex-direction: column;
				}

				div.button {
					width: 120px;
					margin-top: 8px;
					font-size: 14px;
					color: #989898;
					cursor: pointer;
				}

				div.button:hover {
					color: var(--hover_color);
				}
			}
		}
	}

	.beian {
		position: absolute;
		bottom: 16px;
		left: 435px;
		width: 144px;
		height: 20px;
		// background-color: #989898;
	}
</style>