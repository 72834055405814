import { render, staticRenderFns } from "./Home_Footer.vue?vue&type=template&id=bfdc3d74&scoped=true&"
import script from "./Home_Footer.vue?vue&type=script&lang=js&"
export * from "./Home_Footer.vue?vue&type=script&lang=js&"
import style0 from "./Home_Footer.vue?vue&type=style&index=0&id=bfdc3d74&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfdc3d74",
  null
  
)

export default component.exports