<template>
	<div id="app" class="app">
		<HomeHead v-if="vshow" :search_type_value="search_type + ''" :search_content="search" :activeIndex="tab_name"
			:org="org">
		</HomeHead>
		<router-view />
		<HomeFooter v-if="vshow" :org="org"></HomeFooter>
	</div>
</template>
<script>
	import {
		getQueryVariable
	} from "./utils/tools.js";
	import HomeHead from "./components/Home_Header.vue";
	import HomeFooter from "./components/Home_Footer.vue";
	export default {
		components: {
			HomeHead,
			HomeFooter,
		},
		data() {
			return {
				tab_name: "1",
				search_type: "1",
				search: "",
				org: "",
				vshow: true
			};
		},
		created() {
			// localStorage   sessionStorage
			// let geturl = window.location.href
			// let getqyinfo = geturl.split('?')[1]
			// let getqys = new URLSearchParams('?' + getqyinfo)
			// sessionStorage.setItem("org", getqys.get('org'));

			if (getQueryVariable("tab_name"))
				this.tab_name = getQueryVariable("tab_name");
			if (getQueryVariable("search_type"))
				this.search_type = getQueryVariable("search_type");
			if (getQueryVariable("search"))
				this.search = decodeURIComponent(getQueryVariable("search"));
			if (getQueryVariable("org"))
				this.org = getQueryVariable("org");
			if (this.isMobile()) {
				this.vshow = false
				this.$router.replace('/Guide');
			}
			// else {
			// 	this.vshow = true
			// 	this.$router.replace('/');
			// }
		},
		methods: {
			isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				return flag;
			}
		},
	};
</script>
<style lang="less">
	body {
		margin: 0px;
		padding: 0px;

		.app {
			display: flex;
			flex-direction: column;
			background-color: #f6f6f6;
			width: 100vw;
			height: auto;
		}
	}
</style>
