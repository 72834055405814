<template>
	<div class="header-total">
		<div class="content-top">
			<div class="banner-top">
				<img v-if="$app == 'kh'" src="../assets/kh/promo@2x.png" width="148px" height="94px" class="pet" />
				<img v-else-if="$app == 'dy'" src="../assets/dy/promo@2x.png" width="148px" height="94px"
					class="pet-dy" />
				<div class="button-array">
					<div class="button-bar">
						<img class="button-img" src="../assets/kh/ico_wx@2x.png" />

						<el-popover placement="bottom" width="200" trigger="click">
							<div style="
                  display: flex;
                  flex-direction: column;
                  width: 200px;
                  height: 225px;
                  align-items: center;
                ">
								<div style="
                    font-size: 14px;
                    line-height: 24px;
                    color: #333333;
                    text-align: center;
                    margin-top: 15px;
                    opacity: 1;
                  ">
									<div>{{ wx_code == "" ? "加载中" : "请使用微信扫一扫" }}</div>
									<!-- <div>{{ wx_code == "" ? "" : "识别二维码预定" }}</div> -->
								</div>
								<img v-show="wx_code != ''" style="margin-top: 15px; width: 150px; height: 150px"
									:src="wx_code" />
							</div>
							<div slot="reference" class="button-txt" @click="clickButton(1)">
								微信
							</div>
						</el-popover>
					</div>
					<div class="line"></div>
					<div class="button-bar">
						<img class="button-img" src="../assets/kh/ico_sc@2x.png" />
						<div class="button-txt" @click="clickButton(2)">收藏</div>
					</div>
				</div>
				<div class="button-array pos-right">
					<div class="button-bar">
						<div class="button-txt" @click="clickButton(3)">关于我们</div>
					</div>
					<div class="line"></div>
					<div class="button-bar">
						<div class="button-txt" @click="clickButton(4)">咨询客服</div>
					</div>
				</div>
			</div>
		</div>
		<div class="content-bottom">
			<div class="banner-bottom">
				<div class="logo-banner">
					<img v-if="$app == 'kh'" class="logo" src="../assets/kh/logo@2x.png" width="294px" height="42px" />
					<!-- 大运判断地区 -->
					<section v-else-if="$app == 'dy'">
						<img v-if="org == 1" class="logo" src="../assets/dy/dllogo.png" width="294px" height="42px" />
						<img v-else-if="org == 2" class="logo" src="../assets/dy/jllogo.png" width="294px"
							height="42px" />
						<img v-else-if="org == 3" class="logo" src="../assets/dy/hrblogo.png" width="294px"
							height="42px" />
						<img v-else class="logo" src="../assets/dy/logo@2x.png" width="294px" height="42px" />
					</section>


					<div class="search-banner">
						<el-select v-model="search_type_value" placeholder="请选择" class="search-type-select">
							<el-option v-for="item in search_type_options" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
						<div class="input-search">
							<el-input v-model="search_content" placeholder="请搜索关键词" @keyup.enter.native="clickSearch">
							</el-input>
						</div>
						<div class="search-btn" @click="clickSearch">搜索</div>
					</div>
					<img v-if="$app == 'kh'" class="kf-phone" src="../assets/kh/top_dh@2x.png" width="263px"
						height="40px" />

					<section v-else-if="$app == 'dy'">
						<img v-if="org == 1" class="kf-phone" src="../assets/dy/dl_top_dh.png" width="263px"
							height="40px" />
						<img v-else-if="org == 2" class="kf-phone" src="../assets/dy/jl_top_dh.png" width="263px"
							height="40px" />
						<img v-else-if="org == 3" class="kf-phone" src="../assets/dy/hrb_top_dh.png" width="263px"
							height="40px" />
						<img v-else class="kf-phone" src="../assets/dy/top_dh.png" width="263px" height="40px" />
					</section>
				</div>
				<div class="tab-banner">
					<el-menu :default-active="activeIndex == null ? '1' : activeIndex" mode="horizontal"
						@select="handleSelect">
						<el-menu-item index="1">首页</el-menu-item>
						<el-submenu index="2">
							<template slot="title">跟团游</template>
							<el-menu-item index="2-1">国内跟团</el-menu-item>
							<el-menu-item index="2-3">出境游</el-menu-item>
							<el-menu-item index="2-2">省内跟团</el-menu-item>
						</el-submenu>
						<el-menu-item index="16">出境游</el-menu-item>
						<el-submenu index="6">
							<template slot="title">自由行</template>
							<el-menu-item index="6-1">Clubmed</el-menu-item>
							<el-menu-item index="6-2">长隆</el-menu-item>
							<el-menu-item index="6-3">酒店/公寓/民宿</el-menu-item>
							<el-menu-item index="6-4">机票</el-menu-item>
							<el-menu-item index="6-5">租车</el-menu-item>
							<el-menu-item v-if="$app == 'kh'" index="6-6">康辉定制</el-menu-item>
							<el-menu-item v-else-if="$app == 'dy'" index="6-6">大运通定制</el-menu-item>
						</el-submenu>
						<el-menu-item index="4">景点门票</el-menu-item>
						<el-menu-item index="7">全球签证</el-menu-item>
						<el-submenu index="3">
							<template slot="title">东北游</template>
							<el-menu-item index="3-1">辽宁</el-menu-item>
							<el-menu-item index="3-2">吉林</el-menu-item>
							<el-menu-item index="3-3">黑龙江</el-menu-item>
							<el-menu-item index="3-4">大连</el-menu-item>
						</el-submenu>
						<el-menu-item index="18">海岛游</el-menu-item>
						<el-submenu index="11">
							<template slot="title">火车游</template>
							<el-menu-item index="11-1">正班火车</el-menu-item>
							<el-menu-item index="11-2">专列游</el-menu-item>
						</el-submenu>
						<el-submenu index="10">
							<template slot="title">邮轮游</template>
							<el-menu-item index="10-1">国内邮轮</el-menu-item>
							<el-menu-item index="10-2">国际邮轮</el-menu-item>
						</el-submenu>
						<el-menu-item index="5">研学·亲子</el-menu-item>
						<el-menu-item index="14">旅居康养</el-menu-item>
						<el-menu-item index="8">红色培训</el-menu-item>
						<el-submenu index="12">
							<template slot="title">房车自驾</template>
							<el-menu-item index="12-1">房车跟团游</el-menu-item>
							<el-menu-item index="12-2">东三省房车租车</el-menu-item>
						</el-submenu>
						<el-menu-item index="13">会议展览</el-menu-item>

						<el-menu-item index="9" v-if="false">亲子游</el-menu-item>
						<el-menu-item index="15" v-if="false">定制游</el-menu-item>
					</el-menu>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getDetailCode
	} from "@/api/index";
	import {
		addFavorite
	} from "../utils/tools.js";
	export default {
		props: {
			activeIndex: String,
			search_type_value: {
				type: String,
				default: "1",
			},
			search_content: {
				type: String,
				default: "",
			},
			org: {
				type: String,
				default: "",
			}
		},
		data() {
			return {
				search_type_options: [{
						value: "1",
						label: "跟团游",
					},
					{
						value: "2",
						label: "景点门票",
					},
					{
						value: "3",
						label: "火车游",
					},
					{
						value: "4",
						label: "邮轮游",
					},
					{
						value: "5",
						label: "研学·亲子",
					},
					{
						value: "6",
						label: "出境游",
					},
					{
						value: "7",
						label: "海岛游",
					},
				],
				wx_code: "",
			};
		},
		mounted() {
			// this.org = sessionStorage.getItem('org')
		},
		methods: {
			clickSearch() {
				let org = this.$route.query.org ? this.$route.query.org : ''
				if (this.search_content != "") {
					if (this.search_type_value == "1") {
						//跟团
						window.open(
							"/ProductList?type=1&tab_name=2-1&search=" +
							this.search_content +
							"&search_type=" +
							this.search_type_value +
							"&org=" + org,
							"_blank"
						);
					} else if (this.search_type_value == "2") {
						//门票
						window.open(
							"/ProductList?type=3&tab_name=4&search=" +
							this.search_content +
							"&search_type=" +
							this.search_type_value +
							"&org=" + org,
							"_blank"
						);
					} else if (this.search_type_value == "3") {
						//火车
						window.open(
							"/ProductList?type=10&tab_name=11-1&search=" +
							this.search_content +
							"&search_type=" +
							this.search_type_value +
							"&org=" + org,
							"_blank"
						);
					} else if (this.search_type_value == "4") {
						//邮轮
						window.open(
							"/ProductList?type=9&tab_name=10-1&search=" +
							this.search_content +
							"&search_type=" +
							this.search_type_value +
							"&org=" + org,
							"_blank"
						);
					} else if (this.search_type_value == "5") {
						//研学
						window.open(
							"/ProductList?type=4&tab_name=5&search=" +
							this.search_content +
							"&search_type=" +
							this.search_type_value +
							"&org=" + org,
							"_blank"
						);
					} else if (this.search_type_value == "6") {
						//出境游
						window.open(
							"/ProductList?type=16&tab_name=16&search=" +
							this.search_content +
							"&search_type=" +
							this.search_type_value +
							"&org=" + org,
							"_blank"
						);
					} else if (this.search_type_value == "7") {
						//海岛游
						window.open(
							"/ProductList?type=18&tab_name=18&search=" +
							this.search_content +
							"&search_type=" +
							this.search_type_value +
							"&org=" + org,
							"_blank"
						);
					}
				} else
					this.$message({
						message: "搜索内容不能为空",
						type: "warning",
					});
			},
			handleSelect(e) {
				let org = this.$route.query.org ? this.$route.query.org : ''
				console.log(e);
				if (e == "1") {
					//首页
					window.open("/?tab_name=1&org=" + org, "_blank");
				} else if (e == "2-1") {
					//国内跟团
					window.open("/ProductList?type=1&tab_name=2-1&org=" + org, "_blank");
				} else if (e == "2-2") {
					//省内跟团
					window.open("/ProductList?type=1&tab_name=2-2&org=" + org, "_blank");
				} else if (e == "2-3") {
					//出境游
					window.open("/ProductList?type=1&tab_name=2-3&org=" + org, "_blank");
				} else if (e == "3-1") {
					//当地参团 辽宁
					window.open("/ProductList?type=2&tab_name=3-1&org=" + org, "_blank");
				} else if (e == "3-2") {
					//当地参团 吉林
					window.open("/ProductList?type=2&tab_name=3-2&org=" + org, "_blank");
				} else if (e == "3-3") {
					//当地参团 黑龙江
					window.open("/ProductList?type=2&tab_name=3-3&org=" + org, "_blank");
				} else if (e == "3-4") {
					//当地参团 大连
					window.open("/ProductList?type=2&tab_name=3-4&org=" + org, "_blank");
				} else if (e == "4") {
					//周边游
					window.open("/ProductList?type=3&tab_name=4&org=" + org, "_blank");
				} else if (e == "5") {
					//研学
					window.open("/ProductList?type=4&tab_name=5&org=" + org, "_blank");
				} else if (e == "6-1") {
					//自由行-clubmeb
					window.open("/ProductList?type=5&tab_name=6-1&org=" + org, "_blank");
				} else if (e == "6-2") {
					//自由行-长隆
					window.open("/ProductList?type=5&tab_name=6-2&org=" + org, "_blank");
				} else if (e == "7") {
					//签证
					window.open("/Visa?tab_name=7&org=" + org, "_blank");
				} else if (e == "6-3") {
					//自由行-酒店
					window.open("/ProductList?type=5&tab_name=6-3&org=" + org, "_blank");
				} else if (e == "6-4") {
					//自由行-机票
					window.open("/About?index=9&tab_name=" + e + "&org=" + org, "_blank");
				} else if (e == "6-5") {
					//自由行-租车
					window.open("/About?index=10&tab_name=" + e + "&org=" + org, "_blank");
				} else if (e == "6-6") {
					//自由行-定制
					window.open("/About?index=11&tab_name=" + e + "&org=" + org, "_blank");
				} else if (e == "8") {
					//红色之旅
					window.open("/ProductList?type=7&tab_name=8&org=" + org, "_blank");
				} else if (e == "9") {
					//亲子
					window.open("/ProductList?type=8&tab_name=9&org=" + org, "_blank");
				} else if (e == "10-1") {
					//国内邮轮
					window.open("/ProductList?type=9&tab_name=10-1&org=" + org, "_blank");
				} else if (e == "10-2") {
					//国际邮轮
					window.open("/ProductList?type=9&tab_name=10-2&org=" + org, "_blank");
				} else if (e == "11-1") {
					//正班火车
					window.open("/ProductList?type=10&tab_name=11-1&org=" + org, "_blank");
				} else if (e == "11-2") {
					//专列游
					window.open("/ProductList?type=10&tab_name=11-2&org=" + org, "_blank");
				} else if (e == "12-1") {
					//房车/自驾 跟团游
					window.open("/ProductList?type=11&tab_name=12-1&org=" + org, "_blank");
				} else if (e == "12-2") {
					//房车/自驾 门票
					window.open("/ProductList?type=11&tab_name=12-2&org=" + org, "_blank");
				} else if (e == "13") {
					//会议会展
					// window.open("/ProductList?type=12&tab_name=13", "_blank");
					window.open("/About?index=0&tab_name=" + e + "&org=" + org, "_blank");
				} else if (e == "14") {
					//旅居康养
					window.open("/ProductList?type=13&tab_name=14&org=" + org, "_blank");
				} else if (e == "15") {
					//定制游
					window.open("/ProductList?type=14&tab_name=15&org=" + org, "_blank");
				} else if (e == "18") {
					//海岛游
					window.open("/ProductList?type=18&tab_name=18&org=" + org, "_blank");
				} else if (e == "16") {
					//出境游
					window.open("/ProductList?type=16&tab_name=16&org=" + org, "_blank");
				}
			},
			clickButton(e) {
				let org = this.$route.query.org ? this.$route.query.org : ''
				if (e == 1) {
					//微信扫一扫
					let app;
					if (this.$app == "kh") app = 3;
					else if (this.$app == "dy") app = 2;
					getDetailCode("pc", "pages/main/main", app).then((res) => {
						this.wx_code = res;
					});
				} else if (e == 2) {
					//收藏
					addFavorite();
				} else if (e == 3) {
					//关于我们
					window.open("/About?index=3&org=" + org, "_blank");
				} else if (e == 4) {
					//联系客服
					window.open("/About?index=7&org=" + org, "_blank");
				}
			},
		},
	};
</script>
<style lang="less" scoped>
	.header-total /deep/ .el-menu--horizontal>.el-menu-item.is-active {
		border-bottom: 4px solid var(--hover_color);
	}

	.header-total /deep/ .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
		border-bottom: 4px solid var(--hover_color);
	}

	.header-total /deep/ .el-menu.el-menu--horizontal {
		border-bottom: 1px solid var(--hover_color);
	}

	.header-total /deep/ .el-menu-item {
		padding-top: 0px;
		padding-bottom: 0px;
		padding-left: 12px;
		padding-right: 12px;
	}

	.header-total /deep/ .el-submenu__title {
		padding-top: 0px;
		padding-bottom: 0px;
		padding-left: 12px;
		padding-right: 12px;
	}

	.header-total /deep/ .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
		margin-left: 2px;
	}

	.search-type-select /deep/ .el-input__inner {
		border: none;
		border-right: 1px solid #ccc;
		border-radius: 0px;
		height: 38px;
		width: 103px;
	}

	.input-search /deep/ .el-input__inner {
		border: none;
		border-right: 1px solid #ccc;
		border-radius: 0px;
		height: 38px;
		width: 246px;
	}

	.input-search /deep/ .el-input {
		width: 246px;
	}

	.header-total {
		display: flex;
		flex-direction: column;
		width: 100%;
		min-width: 1200px;
		background-color: #fff;
		align-items: center;

		.pos-right {
			margin-left: auto;
		}

		.content-top {
			display: flex;
			justify-content: center;
			width: 100%;
			background-color: #f2f2f2;

			.banner-top {
				display: flex;
				width: 1200px;
				height: 40px;
				position: relative;

				.pet {
					position: absolute;
					right: -130px;
					top: 66px;
					z-index: 1;
				}

				.pet-dy {
					position: absolute;
					right: -130px;
					top: 80px;
					z-index: 1;
				}

				div.button-array {
					display: flex;
					height: 100%;
					align-items: center;

					.line {
						width: 1px;
						height: 8px;
						background-color: #838383;
						margin-left: 25px;
						margin-right: 25px;
					}

					div.button-bar {
						display: flex;
						height: 100%;
						align-items: center;

						img.button-img {
							width: 24px;
							height: 24px;
							margin-right: 2px;
							object-fit: fill;
						}

						div.button-txt {
							font-size: 12px;
							line-height: 40px;
							color: #808080;
							cursor: pointer;
						}

						div.button-txt:hover {
							color: var(--hover_color);
						}
					}
				}
			}
		}

		.content-bottom {
			width: 100%;
			height: 120px;
			border-bottom: 1px solid var(--hover_color);
			display: flex;
			justify-content: center;
			position: relative;

			.banner-bottom {
				width: 1200px;
				height: 120px;
				display: flex;
				flex-direction: column;

				.tab-banner {}

				div.logo-banner {
					margin-top: 15px;
					display: flex;
					width: 100%;
					align-items: center;

					img.logo {
						object-fit: fill;
					}

					.kf-phone {
						margin-left: 15px;
						margin-top: 5px;
					}

					div.search-banner {
						margin-left: 8px;
						width: 441px;
						height: 38px;
						display: flex;
						border: 1px solid var(--hover_color);

						.search-btn {
							width: 92px;
							height: 38px;
							background: var(--hover_color);
							opacity: 1;
							text-align: center;
							font-size: 16px;
							line-height: 38px;
							color: #ffffff;
							opacity: 1;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
</style>