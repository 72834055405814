import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Home',
		component: () => import('../views/Home.vue')
	},
	{
		path: '/About',
		name: 'About',
		component: () => import('../views/About.vue')
	},
	{
		path: '/ProductList',
		name: 'ProductList',
		component: () => import('../views/ProductList.vue')
	},
	{
		path: '/Detail',
		name: 'Detail',
		component: () => import('../views/Detail.vue')
	},
	{
		path: '/NewsList',
		name: 'NewsList',
		component: () => import('../views/NewsList.vue')
	},
	{
		path: '/NewsDetail',
		name: 'NewsDetail',
		component: () => import('../views/NewsDetail.vue')
	},
	{
		path: '/Visa',
		name: 'Visa',
		component: () => import('../views/Visa.vue')
	},
	{
		path: '/VisaDetail',
		name: 'VisaDetail',
		component: () => import('../views/VisaDetail.vue')
	},
	{
		path: '/VisaMoreCountry',
		name: 'VisaMoreCountry',
		component: () => import('../views/VisaMoreCountry.vue')
	},
	{
		path: '/VisaMoreProduct',
		name: 'VisaMoreProduct',
		component: () => import('../views/VisaMoreProduct.vue')
	},
	{
		path: '/ShopList',
		name: 'ShopList',
		component: () => import('../views/ShopList.vue')
	},
	{
		path: '/Guide',
		name: 'Guide',
		component: () => import('../views/Guide.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
