// 导入axios
import axios from 'axios';
import Global from './global'

// 进行一些全局配置
// 公共路由(网络请求地址)
axios.defaults.baseURL = 'https://api.dayuntong.com';
// 请求响应超时时间
axios.defaults.timeout = 10000;

// 首先对拦截器的请求进行设置，并在方法中return config，此处为固定格式
axios.interceptors.request.use(config => {
    // 表示在配置中的设置头消息的字段Authorization为从本地获取的token值
    if (config.url.indexOf('https://service.sweetour.com/m/m_Service.asmx/M_InterfaceTrue') == -1)
        config.headers.Authorization = localStorage.getItem('token')
    return config
})

// 封装自己的get/post方法
export default {
    get: function (path = '', data = {}) {
        return new Promise(function (resolve, reject) {
            axios.get(path, {
                    params: data
                })
                .then(function (response) {
                    // 按需求来，这里我需要的是response.data，所以返回response.data，一般直接返回response
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    post: function (path = '', data = {}) {
        return new Promise(function (resolve, reject) {
            axios.post(path, data)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },

    postUrlencode: function (path = '', data = {}) {
        return new Promise(function (resolve, reject) {
            axios.post(path, data, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
                .then(function (response) {
                    let ss = response.data.substring(76);
                    let mys = ss.substring(0, ss.length - 9);
                    let jsArr = JSON.parse(mys);
                    let myxun = jsArr.tohresponse.svccont;
                    resolve(myxun);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },

    postToken: function (path = '', data = {}, type = 2) {
        return new Promise(function (resolve, reject) {
            let token = localStorage.getItem('token');
            if (token == null) {
                axios.post(Global.URL_SWEET_MER + '/api/services/app/TeamAll/GetToken?organizationId=' + type, data)
                    .then(function (response) {
                        token = response.data.result
                        localStorage.setItem('token', token)
                        axios.post(path, data)
                            .then(function (response) {
                                resolve(response.data);
                            })
                            .catch(function (error) {
                                reject(error);
                            });
                    })
                    .catch(function (error) {});
            } else {
                axios.post(path, data)
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        // reject(error);
                        if ((error + "").indexOf('401') != -1) {
                            localStorage.removeItem('token')
                            axios.post(Global.URL_SWEET_MER + '/api/services/app/TeamAll/GetToken?organizationId=' + type, data)
                                .then(function (response) {
                                    token = response.data.result
                                    localStorage.setItem('token', token)
                                    axios.post(path, data)
                                        .then(function (response) {
                                            resolve(response.data);
                                        })
                                        .catch(function (error) {
                                            reject(error);
                                        });
                                })
                                .catch(function (error) {});
                        }
                    });
            }
        });
    }
};